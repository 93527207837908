<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <b-row>
                    <b-col lg="12" sm="12">
                      <b-table-simple bordered>
                        <b-tr>
                          <b-th style="width:20%" class="text-right">{{ $t('elearning_config.fiscal_year') }}</b-th>
                          <b-th style="width:3%" class="text-center">:</b-th>
                          <b-td style="width:27%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.fiscal_year_bn : accommodation.fiscal_year }}</b-td>
                          <b-th style="width:14%" class="text-right" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $t('org_pro.organization') }}</b-th>
                          <b-th style="width:3%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">:</b-th>
                          <b-td style="width:33%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ ($i18n.locale=='bn') ? accommodation.org_bn : accommodation.org }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th style="width:20%" class="text-right">{{ $t('org_pro.office_type') }}</b-th>
                          <b-th style="width:3%" class="text-center">:</b-th>
                          <b-td style="width:27%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.office_type_bn : accommodation.office_type }}</b-td>
                          <b-th style="width:14%" class="text-right">{{ $t('org_pro.office_namel') }}</b-th>
                          <b-th style="width:3%" class="text-center">:</b-th>
                          <b-td style="width:33%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.office_name_bn : accommodation.office_name }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th style="width:20%" class="text-right">{{ $t('elearning_config.guest_house_name') }}</b-th>
                          <b-th style="width:3%" class="text-center">:</b-th>
                          <b-td style="width:27%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.guest_house_bn : accommodation.guest_house }}</b-td>
                          <b-th style="width:14%" class="text-right">{{ $t('globalTrans.email') }}</b-th>
                          <b-th style="width:3%" class="text-center">:</b-th>
                          <b-td style="width:33%" class="text-left">{{ accommodation.email }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th style="width:14%" class="text-right">{{ $t('elearning_accommodation.check_in') }}</b-th>
                          <b-th style="width:3%" class="text-center">:</b-th>
                          <b-td style="width:33%" class="text-left">{{ accommodation.check_in | dateFormat }}</b-td>
                          <b-th style="width:14%" class="text-right">{{ $t('elearning_accommodation.check_out') }}</b-th>
                          <b-th style="width:3%" class="text-center">:</b-th>
                          <b-td style="width:33%" class="text-left">{{ accommodation.check_out | dateFormat }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th style="width:20%" class="text-right">{{ $t('elearning_accommodation.org_name') }}</b-th>
                          <b-th style="width:3%" class="text-center">:</b-th>
                          <b-td style="width:27%" class="text-left">
                              <!-- {{ ($i18n.locale=='bn') ? accommodation.guest_org_bn : accommodation.guest_org }} -->
                              <slot v-if="accommodation.guest_org_id === 999">
                                    {{accommodation.guest_org_name}}
                                </slot>
                                <slot v-else>
                                    {{ ($i18n.locale==='bn') ? accommodation.guest_org_bn : accommodation.guest_org }}
                                </slot>
                          </b-td>
                          <b-th style="width:14%" class="text-right">{{ $t('elearning_accommodation.number_of_days') }}</b-th>
                          <b-th style="width:3%" class="text-center">:</b-th>
                          <b-td style="width:33%" class="text-left">{{ accommodation.no_of_days }}</b-td>
                        </b-tr>
                      </b-table-simple>
                      <b-table-simple bordered>
                        <b-tr>
                          <b-th class="text-center">{{ $t('elearning_accommodation.bill_details') }}</b-th>
                        </b-tr>
                      </b-table-simple>
                      <b-table-simple bordered>
                        <b-tr>
                            <b-th style="width:20%">{{ $t('elearning_config.room_type') }}</b-th>
                            <b-th style="width:15%">{{ $t('elearning_config.floor_no') }}</b-th>
                            <b-th style="width:15%">{{ $t('elearning_config.room_no') }}</b-th>
                            <b-th style="width:15%">{{ $t('elearning_config.room_rent') }}</b-th>
                            <b-th style="width:15%">{{ $t('elearning_accommodation.number_of_days') }}</b-th>
                            <b-th style="width:20%">{{ $t('elearning_accommodation.bill_amount') }}</b-th>
                        </b-tr>
                        <b-tr v-for="(item,index) in accommodation.accommodation_room_details" :key="index">
                          <b-td style="width:20%">{{ $store.state.TrainingElearning.commonObj.roomTypeList.find(doc => doc.value === parseInt(item.room_type_id)).text_en }}</b-td>
                          <b-td style="width:15%">{{ item.floor_no }}</b-td>
                          <b-td style="width:15%">{{ $store.state.TrainingElearning.commonObj.roomList.find(doc => doc.value === parseInt(item.room_no)).text_en }}</b-td>
                          <b-td style="width:15%">{{ $n(item.room_rent) }}</b-td>
                          <b-td style="width:15%">{{ $n(accommodation.no_of_days) }}</b-td>
                          <b-td style="width:20%">{{ $n(item.room_rent * accommodation.no_of_days) }}</b-td>
                        </b-tr>
                        <!-- <b-tr>
                          <b-th colspan="5" class="text-right">{{ $t('globalTrans.tax') }}</b-th>
                          <b-td>{{ $n(accommodation.tax) }}</b-td>
                        </b-tr> -->
                        <b-tr>
                          <b-th colspan="5" class="text-right">{{ $t('globalTrans.total') }}</b-th>
                          <b-td>{{ $n(accommodation.payment_amount) }}</b-td>
                        </b-tr>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
.table-form th, td{
  padding: 3px !important;
  margin: 1px !important;
  vertical-align: middle !important;
  font-size: 12px;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { accommodationManagementShow } from '../../api/routes'

export default {
  props: ['id'],
  created () {
    if (this.id) {
        this.getAccommodationData()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      officeTypeList: [],
      accommodation: {},
      guestHouseList: [],
      loading: false,
      officeList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
        this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
        this.officeList = this.getOfficeList(newValue)
        this.guestHouseList = this.getGuestHouseList(newValue)
    }
  },
  methods: {
    async getAccommodationData () {
      this.loading = true
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, accommodationManagementShow + '/' + tmpData.accommodation_management_id)
      this.accommodation = JSON.parse(JSON.stringify(result.data))
      const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(this.accommodation.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org = orgObj.text_en
              orgData.org_bn = orgObj.text_bn
            } else {
              orgData.org = ''
              orgData.org_bn = ''
            }

            const guestHouseObj = this.$store.state.TrainingElearning.commonObj.guestHouseList.find(doc => doc.value === parseInt(this.accommodation.guest_house_id))
            const guestHouseData = {}
            if (typeof guestHouseObj !== 'undefined') {
              guestHouseData.guest_house = guestHouseObj.text_en
              guestHouseData.guest_house_bn = guestHouseObj.text_bn
            } else {
              guestHouseData.guest_house = ''
              guestHouseData.guest_house_bn = ''
            }

             const officeTypeObj = this.$store.state.commonObj.officeTypeList.find(doc => doc.value === parseInt(this.accommodation.office_type_id))
            const officeTypeData = {}
            if (typeof officeTypeObj !== 'undefined') {
              officeTypeData.office_type = officeTypeObj.text_en
              officeTypeData.office_type_bn = officeTypeObj.text_bn
            } else {
              officeTypeData.office_type = ''
              officeTypeData.office_type_bn = ''
            }

            const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(this.accommodation.fiscal_year_id))
            const fiscalYearData = {}
            if (typeof fiscalYearObj !== 'undefined') {
              fiscalYearData.fiscal_year = fiscalYearObj.text_en
              fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              fiscalYearData.fiscal_year = ''
              fiscalYearData.fiscal_year_bn = ''
            }

            const OfficeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(this.accommodation.office_id))
            const OfficeData = {}
            if (typeof OfficeObj !== 'undefined') {
              OfficeData.office_name = OfficeObj.text_en
              OfficeData.office_name_bn = OfficeObj.text_bn
            } else {
              OfficeData.office_name = ''
              OfficeData.office_name_bn = ''
            }

            const foodPackageObj = this.$store.state.TrainingElearning.commonObj.foodPackageList.find(doc => doc.value === parseInt(this.accommodation.food_package_id))
            const foodPackageData = {}
            if (typeof foodPackageObj !== 'undefined') {
              foodPackageData.foor_package = foodPackageObj.text_en
              foodPackageData.foor_package_bn = foodPackageObj.text_bn
            } else {
              foodPackageData.foor_package = ''
              foodPackageData.foor_package_bn = ''
            }

            this.accommodation.accommodation_room_details = this.accommodation.accommodation_room_details.map(item => {
              const date1 = new Date(this.accommodation.check_in)
              const date2 = new Date()
              const diffTime = Math.abs(date2 - date1)
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
              const billData = {}
              billData.bill = item.room_rent * diffDays
              billData.days = diffDays
              const roomTypeObj = this.$store.state.TrainingElearning.commonObj.roomTypeList.find(roomTypeList => roomTypeList.value === parseInt(item.room_type_id))
              const roomTypeData = {}
              if (typeof roomTypeObj !== 'undefined') {
                roomTypeData.room_type = roomTypeObj.text_en
                roomTypeData.room_type_bn = roomTypeObj.text_bn
              } else {
                roomTypeData.room_type = ''
                roomTypeData.room_type_bn = ''
              }
              const roomObj = this.$store.state.TrainingElearning.commonObj.roomList.find(roomTypeList => roomTypeList.value === parseInt(item.room_no))
              const roomData = {}
              if (typeof roomObj !== 'undefined') {
                roomData.room = roomObj.text_en
                roomData.room_bn = roomObj.text_bn
              } else {
                roomData.room = ''
                roomData.room_bn = ''
              }
              return Object.assign({}, item, billData, roomTypeData, roomData)
            })

          officeTypeData.payment_amount = tmpData.total_amount
          officeTypeData.tax = tmpData.tax
          const guestOrgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(this.accommodation.guest_org_id))
          const guestOrgData = {}
          if (typeof guestOrgObj !== 'undefined') {
            guestOrgData.guest_org = guestOrgObj.text_en
            guestOrgData.guest_org_bn = guestOrgObj.text_bn
          } else {
            guestOrgData.guest_org = ''
            guestOrgData.guest_org_bn = ''
          }
          this.accommodation = Object.assign({}, this.accommodation, orgData, guestHouseData, OfficeData, fiscalYearData, officeTypeData, foodPackageData, guestOrgData)
        this.loading = false
    },
    getOfficeTypeList (orgId) {
       const office = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
       if (orgId) {
         return office.filter(item => item.org_id === orgId)
       }
       return office
    },
    getOfficeList (officeTypeId = null) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
            return officeList.filter(office => office.office_type_id === officeTypeId)
        }
        return officeList
    },
    getGuestHouseList (officeTypeId = null) {
        const houseList = this.$store.state.TrainingElearning.commonObj.guestHouseList.filter(item => item.status === 1)
        if (officeTypeId) {
            return houseList.filter(office => office.office_type_id === officeTypeId)
        }
        return houseList
    }
  }
}
</script>
