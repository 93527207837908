<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }" class="table-form">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                        <b-row>
                          <b-col lg="12" sm="12">
                            <b-table-simple bordered>
                              <b-tr>
                                <b-th style="width:20%" class="text-right">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:27%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.fiscal_year_bn : accommodation.fiscal_year }}</b-td>
                                <b-th style="width:14%" class="text-right" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $t('org_pro.organization') }}</b-th>
                                <b-th style="width:3%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">:</b-th>
                                <b-td style="width:33%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ ($i18n.locale=='bn') ? accommodation.org_bn : accommodation.org }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th style="width:20%" class="text-right">{{ $t('org_pro.office_type') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:27%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.office_type_bn : accommodation.office_type }}</b-td>
                                <b-th style="width:14%" class="text-right">{{ $t('org_pro.office_namel') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:33%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.office_name_bn : accommodation.office_name }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th style="width:20%" class="text-right">{{ $t('elearning_config.guest_house_name') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:27%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.guest_house_bn : accommodation.guest_house }}</b-td>
                                <b-th style="width:14%" class="text-right">{{ $t('globalTrans.email') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:33%" class="text-left">{{ accommodation.email }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th style="width:14%" class="text-right">{{ $t('elearning_accommodation.check_in') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:33%" class="text-left">{{ accommodation.check_in | dateFormat }}</b-td>
                                <b-th style="width:14%" class="text-right">{{ $t('elearning_accommodation.check_out') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:33%" class="text-left">{{ accommodation.check_out | dateFormat }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th style="width:20%" class="text-right">{{ $t('elearning_accommodation.org_name') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:27%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.guest_org_bn : accommodation.guest_org }}</b-td>
                                <b-th style="width:14%" class="text-right">{{ $t('elearning_accommodation.number_of_days') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:33%" class="text-left">{{ accommodation.no_of_days }}</b-td>
                              </b-tr>
                            </b-table-simple>
                            <b-table-simple bordered>
                              <b-tr>
                                <b-th class="text-center">{{ $t('elearning_accommodation.bill_details') }}</b-th>
                              </b-tr>
                            </b-table-simple>
                            <b-table-simple bordered>
                              <b-tr>
                                  <b-th style="width:20%">{{ $t('elearning_config.room_type') }}</b-th>
                                  <b-th style="width:15%">{{ $t('elearning_config.floor_no') }}</b-th>
                                  <b-th style="width:15%">{{ $t('elearning_config.room_no') }}</b-th>
                                  <b-th style="width:15%">{{ $t('elearning_config.room_rent') }}</b-th>
                                  <b-th style="width:15%">{{ $t('elearning_accommodation.number_of_days') }}</b-th>
                                  <b-th style="width:20%"  class="text-right">{{ $t('elearning_accommodation.bill_amount') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in accommodation.accommodation_room_details" :key="index">
                                <b-td style="width:20%">{{ $store.state.TrainingElearning.commonObj.roomTypeList.find(doc => doc.value === parseInt(item.room_type_id)).text_en }}</b-td>
                                <b-td style="width:15%" class="text-right">{{ item.floor_no }}</b-td>
                                <b-td style="width:15%" class="text-right">{{ $store.state.TrainingElearning.commonObj.roomList.find(doc => doc.value === parseInt(item.room_no)).text_en }}</b-td>
                                <b-td style="width:15%" class="text-right">{{ $n(item.room_rent) }}</b-td>
                                <b-td style="width:15%" class="text-right">{{ $n(accommodation.no_of_days) }}</b-td>
                                <b-td style="width:20%" class="text-right">{{ $n(item.room_rent * accommodation.no_of_days) }}</b-td>
                              </b-tr>
                              <!-- <b-tr>
                                  <b-th colspan="5" class="text-right">{{ $t('globalTrans.tax') }}(%)</b-th>
                                  <b-td class="text-right">{{ $n(accommodation.tax) }}</b-td>
                              </b-tr> -->
                              <b-tr>
                                  <b-th colspan="5" class="text-right">{{ $t('elearning_accommodation.service_charge') }}</b-th>
                                  <b-td class="text-right">{{ $n(accommodation.service_charge) }}</b-td>
                              </b-tr>
                              <b-tr>
                                  <b-th colspan="5" class="text-right">{{ $t('elearning_accommodation.other_deduction') }}</b-th>
                                  <b-td class="text-right">{{ $n(accommodation.other_deduction) }}</b-td>
                              </b-tr>
                              <b-tr>
                                  <b-th colspan="5" class="text-right">{{ $t('globalTrans.total') }}</b-th>
                                  <b-td class="text-right">{{ $n(accommodation.payment_amount) }}</b-td>
                              </b-tr>
                              <!-- <b-tr>
                                <b-th colspan="5" class="text-right">{{ $t('globalTrans.total') }}</b-th>
                                <b-td>{{ $n(accommodation.total_rent) }}</b-td>
                              </b-tr> -->
                            </b-table-simple>
                          </b-col>
                          <!-- <b-col lg="4" sm="12">
                            <ValidationProvider name="Tax" vid='tax' rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="tax"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('elearning_accommodation.tax')}}
                                </template>
                                <b-form-input
                                    id="tax"
                                    v-model="accommodation.tax"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col> -->
                          <b-col lg="8" sm="12">
                            <ValidationProvider name="Payment Amount"  vid="payment_amount" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="payment_amount"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.payment_amount')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  id="payment_amount"
                                  v-model="accommodation.payment_amount"
                                  :placeholder="$t('globalTrans.payment_amount')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- <b-col>
                            <b-button class="btn-block" type="submit" variant="primary">{{$t('globalTrans.payment')}}</b-button>
                          </b-col> -->
                          <b-col sm="4">
                            <b-button @click="accommodation.is_bypass = 1" class="btn-success" type="submit" variant="success">{{$t('globalTrans.payment')}}</b-button>
                          </b-col>
                        </b-row>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
.table-form th, td{
  padding: 3px !important;
  margin: 1px !important;
  vertical-align: middle !important;
  font-size: 12px;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billGeneratePaymentStore, accommodationManagementShow } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver
  },
  created () {
    if (this.id) {
        this.getAccommodationData()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      officeTypeList: [],
      accommodation: {},
      guestHouseList: [],
      loading: false,
      officeList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
        this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
        this.officeList = this.getOfficeList(newValue)
        this.guestHouseList = this.getGuestHouseList(newValue)
    }
  },
  methods: {
    async getAccommodationData () {
      this.loading = true
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, accommodationManagementShow + '/' + tmpData.accommodation_management_id)
      this.accommodation = JSON.parse(JSON.stringify(result.data))
      this.accommodation.accommodation_bill_generate_id = tmpData.id
      const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(this.accommodation.org_id))
        const orgData = {}
        if (typeof orgObj !== 'undefined') {
          orgData.org = orgObj.text_en
          orgData.org_bn = orgObj.text_bn
        } else {
          orgData.org = ''
          orgData.org_bn = ''
        }

        const guestHouseObj = this.$store.state.TrainingElearning.commonObj.guestHouseList.find(doc => doc.value === parseInt(this.accommodation.guest_house_id))
        const guestHouseData = {}
        if (typeof guestHouseObj !== 'undefined') {
          guestHouseData.guest_house = guestHouseObj.text_en
          guestHouseData.guest_house_bn = guestHouseObj.text_bn
        } else {
          guestHouseData.guest_house = ''
          guestHouseData.guest_house_bn = ''
        }

          const officeTypeObj = this.$store.state.commonObj.officeTypeList.find(doc => doc.value === parseInt(this.accommodation.office_type_id))
        const officeTypeData = {}
        if (typeof officeTypeObj !== 'undefined') {
          officeTypeData.office_type = officeTypeObj.text_en
          officeTypeData.office_type_bn = officeTypeObj.text_bn
        } else {
          officeTypeData.office_type = ''
          officeTypeData.office_type_bn = ''
        }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(this.accommodation.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }

          const OfficeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(this.accommodation.office_id))
          const OfficeData = {}
          if (typeof OfficeObj !== 'undefined') {
            OfficeData.office_name = OfficeObj.text_en
            OfficeData.office_name_bn = OfficeObj.text_bn
          } else {
            OfficeData.office_name = ''
            OfficeData.office_name_bn = ''
          }

          const foodPackageObj = this.$store.state.TrainingElearning.commonObj.foodPackageList.find(doc => doc.value === parseInt(this.accommodation.food_package_id))
          const foodPackageData = {}
          if (typeof foodPackageObj !== 'undefined') {
            foodPackageData.foor_package = foodPackageObj.text_en
            foodPackageData.foor_package_bn = foodPackageObj.text_bn
          } else {
            foodPackageData.foor_package = ''
            foodPackageData.foor_package_bn = ''
          }
        this.accommodation.accommodation_room_details = this.accommodation.accommodation_room_details.map(item => {
          const roomTypeObj = this.$store.state.TrainingElearning.commonObj.roomTypeList.find(roomTypeList => roomTypeList.value === parseInt(item.room_type_id))
          const roomTypeData = {}
          if (typeof roomTypeObj !== 'undefined') {
            roomTypeData.room_type = roomTypeObj.text_en
            roomTypeData.room_type_bn = roomTypeObj.text_bn
          } else {
            roomTypeData.room_type = ''
            roomTypeData.room_type_bn = ''
          }
          const roomObj = this.$store.state.TrainingElearning.commonObj.roomList.find(roomTypeList => roomTypeList.value === parseInt(item.room_no))
          const roomData = {}
          if (typeof roomObj !== 'undefined') {
            roomData.room = roomObj.text_en
            roomData.room_bn = roomObj.text_bn
          } else {
            roomData.room = ''
            roomData.room_bn = ''
          }
          return Object.assign({}, item, roomTypeData, roomData)
        })

        officeTypeData.payment_amount = tmpData.total_amount
        officeTypeData.tax = tmpData.tax
        officeTypeData.service_charge = tmpData.service_charge
        officeTypeData.other_deduction = tmpData.other_deduction
        const guestOrgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(this.accommodation.guest_org_id))
        const guestOrgData = {}
        if (typeof guestOrgObj !== 'undefined') {
          guestOrgData.guest_org = guestOrgObj.text_en
          guestOrgData.guest_org_bn = guestOrgObj.text_bn
        } else {
          guestOrgData.guest_org = ''
          guestOrgData.guest_org_bn = ''
        }
        this.accommodation = Object.assign({}, this.accommodation, orgData, guestHouseData, OfficeData, fiscalYearData, officeTypeData, foodPackageData, guestOrgData)
        this.loading = false
    },
    getOfficeTypeList (orgId) {
       const office = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
       if (orgId) {
         return office.filter(item => item.org_id === orgId)
       }
       return office
    },
    getOfficeList (officeTypeId = null) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
            return officeList.filter(office => office.office_type_id === officeTypeId)
        }
        return officeList
    },
    getGuestHouseList (officeTypeId = null) {
        const houseList = this.$store.state.TrainingElearning.commonObj.guestHouseList.filter(item => item.status === 1)
        if (officeTypeId) {
            return houseList.filter(office => office.office_type_id === officeTypeId)
        }
        return houseList
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.postData(trainingElearningServiceBaseUrl, billGeneratePaymentStore, this.accommodation)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        if (result.success === 2) {
          this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
        } else {
          window.location = result.url
        }
        this.$bvModal.hide('modal-4')
        this.$bvModal.hide('modal-6')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
